<template>
	<div class="loader-wrapper">
		<ZyroLoader
			v-if="isLoading"
			:style="{ '--size': '50px' }"
		/>
	</div>
</template>

<script>
import { mapMutations } from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import { authorize } from '@/api/InstagramApi';
import {
	mapActionsNotifications,
	NOTIFY,
} from '@/store/builder/notifications';

export default {
	name: 'InstagramAuth',
	data() {
		return { isLoading: true };
	},
	created() {
		this.authorizeInstagram();
	},
	methods: {
		...mapActionsNotifications({ notify: NOTIFY }),
		...mapMutations(['setElementData']),
		async authorizeInstagram() {
			try {
				this.isLoading = true;
				const { elementId } = JSON.parse(this.$route.query.state);
				const { username } = await authorize(this.$route.query.code, elementId);

				this.setElementData({
					elementId,
					data: { settings: { username } },
				});
				EventLogApi.logEvent({ eventName: 'instagram.connectAccount' });
				window.close();
			} catch {
				this.isLoading = false;
				this.notify({
					origin: this.$options.name,
					message: 'Failed to authorize your account on Instagram',
					submitLabel: 'Retry',
					submitCallback: this.authorizeInstagram,
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.loader-wrapper {
	position: fixed;
	display: flex;
	width: 100%;
	height: 100%;
}
</style>
